import React from 'react';
import Box from '@webAtoms/Box/Box';
import FeaturedBuilds from '@webSections/FeaturedBuilds';
import { FeaturedBuild as OFeaturedBuild } from '@webOrganisms/FeaturedBuild/FeaturedBuild';
import UpNext from '@webMolecules/UpNext/UpNext';
import FeaturedDetailed from '@localTypes/FeaturedDetailed';
import * as Route from '@routes';

export interface FeaturedBuildProps {
  featured: FeaturedDetailed;
}

const FeaturedBuild: React.FC<FeaturedBuildProps> = ({ featured }) => {
  return (
    <Box>
      <OFeaturedBuild featured={featured} />
      <FeaturedBuilds title="More featured builds" exclude={featured.slug} />
      <UpNext
        links={[
          { label: 'Our process', href: Route.ourProcess() },
          { label: 'Modular homes', href: Route.designs() },
        ]}
      />
    </Box>
  );
};

export default FeaturedBuild;
